<template>
  <div class="warehouse-page">
    <el-form
      inline
      :model="searchForm"
      class="bysearchForm"
      @keyup.enter.native="getDataList()"
    >
      <div class="searchLine"></div>
    </el-form>
    <div class="main-container">
      <div class="aside-wrap">
        <el-table
          height="calc(100vh - 210px)"
          :data="freezerList"
          ref="freezerList"
          border
          :row-class-name="tableRowClassName"
          @selection-change="$selectionChange($event, 'freezerList')"
          @row-click="
            $clickRow(
              $event,
              'freezerList',
              getDataList($event),
              colorChange($event),
            )
          "
          @select="$select(arguments, 'freezerList')"
          @select-all="$clearSelection('freezerList')"
        >
          <el-table-column
            prop="name"
            header-align="center"
            align="center"
            label="取餐柜"
          />
        </el-table>
        <el-pagination
          background
          @size-change="$sizeChange($event, 'freezerList')"
          @current-change="$currentChange($event, 'freezerList')"
          :current-page="freezerListIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="freezerListSize"
          :total="freezerListCount"
          layout="total, sizes, prev, pager, next"
        />
      </div>
      <div class="main-wrap">
        <el-table
          :data="dataList"
          border
          style="width: 100%"
          @selection-change="selectionChangeHandle"
        >
          <el-table-column
            type="selection"
            header-align="center"
            align="center"
            width="50"
          />
          <el-table-column
            prop="name"
            header-align="center"
            align="center"
            label="时段名称"
          />
          <el-table-column
            header-align="center"
            align="center"
            width="190"
            label="操作"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                v-if="isAuth('cc:periodfreezershelf:freezerShelf')"
                @click="
                  $dialog(
                    'freezerShelf',
                    scope.row.id,
                    scope.row.name,
                    searchForm.freezerId,
                    searchForm.freezerName,
                    searchForm.specId,
                    true,
                  )
                "
                >取餐柜中转架</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <list-footer>
          <el-pagination
            :current-page="pageIndex"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            :total="totalPage"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
          />
        </list-footer>
      </div>
    </div>

    <freezer-shelf
      v-if="freezerShelfVisible"
      ref="freezerShelf"
      @success="getDataList"
    />
  </div>
</template>

<script>
import FreezerShelf from './freezer-shlef';
import { normal, listMixin, threeListMixin } from '@/mixins';

export default {
  mixins: [normal, listMixin, threeListMixin],
  data() {
    return {
      searchForm: {
        freezerId: null,
        freezerName: '',
        specId: '',
      },
      dataList: [],
      chooseIndex: 0,
      freezerListIndex: 1,
      freezerListSize: 10,
      freezerListCount: 0,
      freezerList: [],
      orgOptions: [], //  供应商列表
      periodOptions: [], // 时段列表
      freezerOptions: [], // 智能取餐柜列表
      stateOptions: [
        { id: 0, name: '待生效' },
        { id: 1, name: '生效中' },
        { id: 2, name: '已终止' },
      ],
      freezerCategoryVisible: false,
      freezerShelfVisible: false,
    };
  },
  components: {
    FreezerShelf,
  },
  mounted() {
    this.getFreezerList();
  },
  activated() {
    this.getDataList(1);
  },
  methods: {
    tableRowClassName({ row }) {
      // this.
      if (row.id === this.chooseIndex) {
        return 'danger-row';
      } else {
        return '';
      }
    },
    colorChange(row) {
      this.chooseIndex = row.id;
    },
    // 冷柜
    getFreezerList(page) {
      this.$http({
        url: '/cc/freezer/page:detail',
        method: 'get',
        params: {
          page: page ? page : this.freezerListIndex,
          perPage: this.freezerListSize,
          isUse: 1,
          isGroup: 1,
        },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.freezerList = data.data.items;
          this.searchForm.freezerId = this.freezerList[0].id;
          this.searchForm.freezerName = this.freezerList[0].name;
          this.freezerListCount = data.data.total;
          this.getDataList();
        } else {
          this.freezerList = [];
        }
      });
    },
    // 获取数据列表
    getDataList(row) {
      this.searchForm.freezerId = row.id ? row.id : this.searchForm.freezerId;
      this.searchForm.freezerName = row.name
        ? row.name
        : this.searchForm.freezerName;
      this.searchForm.specId = row.specId ? row.specId : this.searchForm.specId;
      // this.searchForm.periodId = row.id ? row.id : this.searchForm.periodId;
      this.$http({
        url: '/cc/global-period/page',
        method: 'get',
        params: {
          page: this.pageIndex,
          limit: this.pageSize,
        },
      }).then(({ data }) => {
        if (data && data.data && data.status === 0) {
          if (data.data.items.length) {
            data.data.items.forEach((item) => {
              if (item.cutOffTime2) {
                item.cutOffTime2show = '前一天' + item.cutOffTime2.substr(0, 5);
              } else {
                item.cutOffTime2show = '无预售';
              }
            });
            this.dataList = data.data.items;
            this.totalPage = data.data.total;
          } else {
            this.dataList = [];
            this.totalPage = 0;
          }
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
      });
    },
  },
};
</script>
